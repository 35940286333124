globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"4bc91426434c9414070bd1b9a89ed6f963126ccd"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

  environment: process.env.NEXT_PUBLIC_ENV,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.2,

  tracePropagationTargets: ['localhost', process.env.BACKEND_URL as string],

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 0.2,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
    Sentry.captureConsoleIntegration({
      levels: ['error'],
    }),
    Sentry.httpClientIntegration({
      failedRequestStatusCodes: [400, 401, 403, 404, 408, 429, 500, 502, 503, 504],
      failedRequestTargets: [],
    }),
  ],
});
